import React from 'react';
import './Spinner.css'

export const Spinner = () => {

    return (
        <div style={{textAlign: "center"}}>
            <span className="loader"></span>
        </div>


    )
}
